import React from 'react';

import { StyledImageHolder, HeldImage } from './ImageHolder.styles';

const ImageHolder = ({
	image,
	title,
	alt,
	className,
	ratio,
	videoUrl,
	thumbnail,
	...otherImageProps
}) => {
	if (!!videoUrl) {
		return (
			<StyledImageHolder className={className} {...otherImageProps}>
				{/* if this has a video then just place an image/ */}
				<a
					srl_video_thumbnail={thumbnail}
					srt_video_autoplay="false"
					srl_video_caption={alt ? alt : ' 1 '}
					href={videoUrl}>
					<img src={thumbnail} alt={alt ? alt : title} title={title} ratio={ratio} />
				</a>
			</StyledImageHolder>
		);
	}

	return (
		<StyledImageHolder className={className} {...otherImageProps}>
			<HeldImage
				fluid={image}
				alt={alt ? alt : title}
				ratio={ratio}
				title={title}
				{...otherImageProps}
			/>
		</StyledImageHolder>
	);
};
export default ImageHolder;
