import React from 'react';
import { graphql } from 'gatsby';
import Page from './Page.react';
import SEO from '../seo';

import { PORTFOLIO_PAGE_ATTRIBUTES, galleryPageProcessor } from '../../queries/queryUtils.react';

const PAGE_ATTRIBUTES = PORTFOLIO_PAGE_ATTRIBUTES;
const AutoGalleryPage = ({ data, props, className }) => {
	// const siteTitle = get(this.props, 'data.site.siteMetadata.title')

	const pageObject = galleryPageProcessor(data.contentfulGalleryPage, PAGE_ATTRIBUTES);
	return (
		// <pre>{JSON.stringify(data.contentfulGalleryPage, null, 4)}</pre>
		<>
			<SEO
				description={data.contentfulGalleryPage.seoDescription.seoDescription}
				title={data.contentfulGalleryPage.seoTitle}
				lang={'en'}
			/>
			<Page
				{...props}
				page={data.contentfulGalleryPage.slug}
				className={className}
				content={pageObject}
				frame={pageObject.frame}
				lightbox={data.contentfulGalleryPage.lightbox}
			/>
		</>
	);
};

export default AutoGalleryPage;

export const pageQuery = graphql`
	query contentfulGalleryPageQuery1($slug: String!) {
		contentfulGalleryPage(slug: { eq: $slug }) {
			slug
			id
			seoTitle
			seoDescription {
				seoDescription
			}
			title: heroTitle
			heroText {
				id
				heroText
				childMarkdownRemark {
					html
				}
			}

			galleries {
				id
				description {
					childMarkdownRemark {
						html
					}
				}
				galleryTitle
				media {
					id
					videoUrl
					media {
						id
						title
						description
						fluid(maxWidth: 1100, quality: 85, resizingBehavior: SCALE) {
							aspectRatio
							base64
							sizes
							src
							srcSet
							srcSetWebp
							srcWebp
						}
						file {
							url
							fileName
							details {
								size
								image {
									width
									height
								}
							}
						}
					}
				}
				row1
				row2
				row3
				row4
				row5
			}
			heroImage {
				id
				title
				description
				file {
					url
				}
				fluid(maxWidth: 1100, quality: 85, resizingBehavior: SCALE) {
					aspectRatio
					base64
					sizes
					src
					srcSet
					srcSetWebp
					srcWebp
				}
			}
			heroSecondaryImage {
				id
				title
				description
				file {
					url
				}
				fluid(maxWidth: 450, quality: 85, resizingBehavior: SCALE) {
					aspectRatio
					base64
					sizes
					src
					srcSet
					srcSetWebp
					srcWebp
				}
			}
			name
			secondaryBlockPosition
			secondaryBlockColumns
			secondaryBlockTitle
			lightbox
			secondaryTextBlock {
				childMarkdownRemark {
					html
				}
			}

			previousPage {
				... on ContentfulGalleryPage {
					id
					name
					id
					name
					excerpt {
						excerpt
					}
					slug
					heroImage {
						file {
							url
							fileName
						}
						fluid(maxWidth: 400, quality: 85, resizingBehavior: SCALE) {
							aspectRatio
							base64
							sizes
							src
							srcSet
							srcSetWebp
							srcWebp
						}
						description
					}
				}
				... on ContentfulPage {
					id
					name
					id
					name
					excerpt {
						excerpt
					}
					slug
					heroImage {
						file {
							url
							fileName
						}
						description
					}
				}
			}
			nextPage {
				... on ContentfulGalleryPage {
					id
					name
					id
					name
					excerpt {
						excerpt
					}
					slug
					heroImage {
						fluid(maxWidth: 400, quality: 85, resizingBehavior: SCALE) {
							aspectRatio
							base64
							sizes
							src
							srcSet
							srcSetWebp
							srcWebp
						}
						file {
							url
							fileName
						}
						description
					}
				}
				... on ContentfulPage {
					id
					name
					id
					name
					excerpt {
						excerpt
					}
					slug
					heroImage {
						file {
							url
							fileName
						}
						description
					}
				}
			}
		}
	}
`;
