import styled from 'styled-components';
import tw from 'twin.macro';
import Button from '../../Blocks/Button/Button.react';
import { Heading2 } from '../../Typography/Typography.react';
import ImageHolder from '../ImageHolder/ImageHolder.react';

export const StyledNextPreviousBlock = styled.section`
	${tw`
    flex
    w-full
    relative
    flex-row
    place-content-between
   
    flex space-x-4
            w-screen
        md:max-w-screen-md
        lg:max-w-screen-lg  mx-auto
  `}
`;

export const StyledNextPrev = styled.div`
	${tw` w-1/2 md:w-5/12`}
	${props =>
		props.previous &&
		tw`
    
  `}

    ${props =>
		props.next &&
		tw`
   
  `}
`;

export const NexPrevImage = styled(ImageHolder)`
	${tw` w-full md:w-10/12 `}
	${props =>
		props.previous &&
		tw`
    
  `}

    ${props =>
		props.next &&
		tw`
      md:ml-1/6
  `}
`;

export const StyledNextPrevCard = styled.div`
	${tw` flex flex-col p-4 bg-white w-full
     relative z-30
    
  `}

	${props =>
		props.image &&
		tw`
    md:w-10/12  -mt-1/4
  `}


	${props =>
		props.previous &&
		props.image &&
		tw`
    ml-1/6
  `}

  ${props =>
		props.next &&
		props.image &&
		tw`
   
  `}
`;

export const NextPrevHeading = styled(Heading2)`
	${tw`
	mt-0
	text-xl leading-tight md:leading-normal md:text-3xl
		`}
`;

export const NextPrevButton = styled(Button)`
	${tw`text-xl md:text-2xl	`}
`;
